import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Contentful`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.contentful.com"
      }}>{`Website`}</a></p>
    <p>{`5 users`}</p>
    <ul>
      <li parentName="ul">{`API calls 2.000.000`}</li>
      <li parentName="ul">{`Records 25K`}</li>
      <li parentName="ul">{`Asset size 1250GB (50M per record)`}</li>
      <li parentName="ul">{`Asset bandwidth .75TB`}</li>
    </ul>
    <h2>{`Sanity`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.sanity.io"
      }}>{`Website`}</a></p>
    <p>{`3 users ($10 per additional User)`}</p>
    <ul>
      <li parentName="ul">{`500k API CDN Requests per month`}</li>
      <li parentName="ul">{`10k Documents ($1 per 250k additional)`}</li>
      <li parentName="ul">{`5GB Assets ($1 per 2GB additional)`}</li>
      <li parentName="ul">{`10GB Bandwidth ($1 per 5GB additional)`}</li>
    </ul>
    <h2>{`Cloudinary`}</h2>
    <ul>
      <li parentName="ul">{`25k write operations (8k adjusted)`}</li>
      <li parentName="ul">{`or 25 GB of storage (8GB adjusted)`}</li>
      <li parentName="ul">{`or 25 GB of net read bandwidth (8GB adjusted)`}</li>
    </ul>
    <h2>{`Hasura`}</h2>
    <p><a parentName="p" {...{
        "href": "https://hasura.io"
      }}>{`Website`}</a></p>
    <p>{`Technical:
Heroku PostgreSQL free tier`}</p>
    <h2>{`Takeshape`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.takeshape.io"
      }}>{`Website`}</a></p>
    <ul>
      <li parentName="ul">{`10,000 API Requests`}</li>
      <li parentName="ul">{`10GB of File Storage ($0.03 / additional GB)`}</li>
      <li parentName="ul">{`10GB of Bandwidth ($0.25 / additional GB)`}</li>
    </ul>
    <p>{`Cons:`}</p>
    <ul>
      <li parentName="ul">{`No roles`}</li>
    </ul>
    <h2>{`Fauna`}</h2>
    <p><a parentName="p" {...{
        "href": "https://fauna.com"
      }}>{`Website`}</a></p>
    <ul>
      <li parentName="ul">{`100k read ops (TRO)`}</li>
      <li parentName="ul">{`50k write ops (TWO)`}</li>
      <li parentName="ul">{`500k compute ops (TCO)`}</li>
      <li parentName="ul">{`100Mb storage`}</li>
    </ul>
    <h2>{`Firebase`}</h2>
    <p><a parentName="p" {...{
        "href": "https://firebase.google.com"
      }}>{`Website`}</a></p>
    <h2>{`Prismic.io`}</h2>
    <p><a parentName="p" {...{
        "href": "https://prismic.io/"
      }}>{`Website`}</a></p>
    <p>{`1 user ($2 per additional)`}</p>
    <ul>
      <li parentName="ul">{`Unlimited API Calls`}</li>
      <li parentName="ul">{`Unlimited Documents`}</li>
      <li parentName="ul">{`Unlimited Assets`}</li>
      <li parentName="ul">{`Unlimited Image Optimizations`}</li>
      <li parentName="ul">{`100 GB of Built-in CDN`}</li>
    </ul>
    <p>{`Cons:`}</p>
    <ul>
      <li parentName="ul">{`Limited create functions`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      